import React from 'react';
import { useHistory } from 'react-router-dom';
import { ForbiddenIcon } from '../icons';

function PageGlobal404() {
    const history = useHistory();

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center p-8 text-center">
                <ForbiddenIcon className="w-24 h-24 mb-6 text-blue-200" aria-hidden="true" />
                <h1 className="text-6xl font-bold text-gray-700 dark:text-gray-200 mb-4">404</h1>
                <p className="text-xl text-gray-700 dark:text-gray-300 mb-4">La page que vous recherchez semble introuvable.</p>
                <p className="text-gray-700 dark:text-gray-300">
                Vérifier l'adresse ou{' '}
                <button className="text-blue-600 hover:underline dark:text-blue-300" onClick={history.goBack}>
                    revenez à la page précédente
                </button>
                .
            </p>
            </div>
        </div>
    );
}

export default PageGlobal404;
