import React, { createContext, ReactNode, useContext, useState } from 'react';

interface State<T> {
    value: T;
    set: React.Dispatch<React.SetStateAction<T>>;
}

export type LoadingContextType = {
    loadings: State<{ [key: string]: boolean }>;
};

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

type LoadingProviderProps = {
    children: ReactNode;
};

interface Loading {
    key: string;
    isLoading: boolean;
}

export const LoadingProvider = ({ children }: LoadingProviderProps) => {
    const [loadings, setLoadings] = useState<{ [key: string]: boolean }>({});

    return (
        <LoadingContext.Provider value={{
            loadings: { value: loadings, set: setLoadings },
        }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};
