import { Windmill } from '@windmill/react-ui';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { Suspense } from 'react';
import { Chart } from 'react-chartjs-2';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';
import App from './App';
import './assets/css/tailwind.output.css';
import ThemedSuspense from './components/ThemedSuspense';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ParametersProvider } from './context/ParametersContext';
import { SidebarProvider } from './context/SidebarContext';
import { ThemeProvider } from './context/ThemeContext';
import myTheme from './myTheme';
import * as serviceWorker from './serviceWorker';
import { LoadingProvider } from './context/LoadingContext';

Chart.register(ChartDataLabels);

const Application = () => {
    const authContext = useAuth()
    return (
        <SWRConfig value={{
            onError: (error, key) => {
                if (error.status !== 401 && error.status !== 403) {
                    authContext.refreshToken();
                }
            },
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            errorRetryInterval: 2000
        }}>
            <SidebarProvider>
                <ParametersProvider>
                    <LoadingProvider>
                        <Suspense fallback={<ThemedSuspense />}>
                            <Windmill usePreferences theme={myTheme}>
                                <App />
                            </Windmill>
                        </Suspense>
                    </LoadingProvider>
                </ParametersProvider>
            </SidebarProvider>
        </SWRConfig>)
}
ReactDOM.render(
    <ThemeProvider>
        <AuthProvider>
            <Application />
        </AuthProvider>
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(null);
